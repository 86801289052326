$type-size-1 : 1.441em;  // ~39.056px
$type-size-2 : 1.353em;  // ~31.248px
$type-size-3 : 1.263em;  // ~25.008px
$type-size-4 : 1.25em;   // ~20px
$type-size-5 : 1em;      // ~16px
$type-size-6 : 0.75em;   // ~12px
$type-size-7 : 0.6875em; // ~11px
$type-size-8 : 0.625em;  // ~10px
  
.button0 {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 4px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    margin: 2px 1px;
    cursor: pointer;
    border-radius: 5px;
}
.button1 {
    background-color: #0417a4;
    border: none;
    color: white;
    padding: 4px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    margin: 2px 1px;
    cursor: pointer;
    border-radius: 5px;
}
.button2 {
    background-color: #e89511;
    border: none;
    color: white;
    padding: 4px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    margin: 2px 1px;
    cursor: pointer;
    border-radius: 5px;
}
.button3 {
    background-color: #d70e22;
    border: none;
    color: white;
    padding: 4px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    margin: 2px 1px;
    cursor: pointer;
    border-radius: 5px;
}

@charset "utf-8";

@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials
//@import "bootstrap-5.3.3/scss/bootstrap.scss";